<template>
  <!-- 汰换 -->
  <div class="replacing">
    <div class="left">
      <img src="@/assets/images/replacing/kexuan.png" alt class="left-header" />
      <div class="left-footer">
        <div
          class="lfi"
          v-for="(item, index) in bagList"
          :key="index"
          @click="gunclick(item, index)"
        >
          <div class="lfip">{{ item.oprice }}</div>
          <img :src="item.oimage" alt />
          <div class="lfin">{{ item.oname }}</div>
        </div>
      </div>
    </div>
    <div class="middle">
      <img src="@/assets/images/replacing/reb.png" alt class="qiu" />
      <img
        src="@/assets/images/replacing/hecheng.png"
        alt
        class="hecheng"
        @click="replacing(gunarrid)"
      />
      <!-- 弹窗 -->
      <div class="tan" v-show="ispop">
        <img :src="newgunbox.awardOimg" alt />
        <div class="tp">{{ newgunbox.awardOprice }}</div>
        <div class="tn">{{ newgunbox.awardOname }}</div>
        <div class="con" @click="colse()">确认</div>
      </div>
    </div>
    <div class="right">
      <div
        class="zt"
        style="
          font-size: 22px;
          font-width: bold;
          color: #fff;
          margin-left: 50px;
          margin-top: 12px;
        "
      >
        选择物品
      </div>
      <div class="rm">
        <div
          class="lfi"
          v-for="(item, index) in gunarr"
          @click="delect(item)"
          :key="index"
        >
          <div class="lfip">{{ item.oprice }}</div>
          <img :src="item.oimage" alt />
          <div class="lfin">{{ item.oname }}</div>
        </div>
      </div>
    </div>
    <div class="header">
      <img src="@/assets/images/replacing/xuanze.png" alt />
    </div>
    <div class="bott">
      <div class="kexuan">
        <div class="ki">
          <div
            class="xi"
            v-for="(item, index) in bagList"
            :key="index"
            @click="gunclick(item, index)"
          >
            <img :src="item.oimage" alt class="xii" />
            <div class="kn">{{ item.oname }}</div>
            <div class="kp">
              <img src="@/assets/images/pk/jinbi.png" alt />
              <div>{{ item.oprice }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="get">
      <img
        src="@/assets/images/replacing/huode.png"
        alt
        @click="replacing(gunarrid)"
        class="getimg"
      />
    </div>
    <div class="getwu" v-show="pop">
      <div class="getwum">
        <div class="gti">
          <img :src="newgunbox.awardOimg" alt class="ni" />
          <div class="tn">{{ newgunbox.awardOname }}</div>
          <div class="tp">
            <img src="@/assets/images/pk/jinbi.png" alt />
            <div>{{ newgunbox.awardOprice }}</div>
          </div>
        </div>
        <div class="con" @click="colse()">确认</div>
      </div>
    </div>
    <div class="yixuan">
      <div class="yihe">已选物品</div>
      <div class="yimain">
        <div class="yimi" v-for="(item, index) in gunarr" :key="index + 'cv'">
          <img
            src="@/assets/images/pk/x.png"
            alt
            class="yclose"
            @click="delect(item)"
          />
          <img :src="item.oimage" alt class="yii" />
          <div class="yn">{{ item.oname }}</div>
          <div class="yp">
            <img src="@/assets/images/pk/jinbi.png" alt />
            <div>{{ item.oprice }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBag } from "../../api/index";
import { updateReplacement } from "../../api/index";
export default {
  inject: ["reload"],
  data() {
    return {
      bagList: [],
      gunclass: false,
      // gunid: [],
      gunarr: [],
      gunarrid: [],
      newgunbox: "",
      ispop: false,
      pop: false,
    };
  },
  mounted() {
    getBag().then((res) => {
      this.bagList = res.data.rows;
    });
  },
  methods: {
    gunclick(id, index) {
      if (this.gunarr.length < 9) {
        this.bagList.splice(index, 1);
        this.gunarr.push(id);
      } else {
        this.$message.error("最多添加九个饰品");
      }
    },
    replacing(id) {
      if (this.gunarr.length < 3) {
        this.$message.error("最少添加三个饰品");
      } else {
        for (const iterator of this.gunarr) {
          this.gunarrid.push(iterator.id);
        }
        let data = {
          boIds: id,
        };
        updateReplacement(data).then((res) => {
          this.newgunbox = res.data.data;
          this.ispop = true;
          this.pop = true;
        });
      }
    },
    delect(ids) {
      this.gunarr.splice(ids, 1);
      this.reload();
    },
    colse() {
      this.ispop = false;
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.replacing {
  width: 1200px;
  height: 410px;
  margin: 50px auto;
  display: flex;

  .left {
    width: 348px;
    height: 410px;
    background-image: url("../../assets/images/replacing/kuang.png");
    background-size: 100% 100%;

    .left-header {
      margin-left: 50px;
      margin-top: 20px;
      display: block;
      width: 95px;
      height: 20px;
    }

    .left-footer {
      height: 320px;
      width: 80%;
      margin-left: 26px;
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      overflow-y: scroll;

      .lfi {
        flex: none;
        width: 120px;
        height: 100px;
        color: #fff;
        font-size: 10px;
        background-image: url("../../assets/images/replacing/box.png");
        background-size: 100% 100%;

        .lfip {
          margin-top: 4px;
          text-align: center;
        }

        img {
          display: block;
          margin-left: 16px;
          width: 80px;
          height: 60px;
        }

        .lfin {
          width: 80%;
          margin: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .middle {
    position: relative;

    .qiu {
      display: block;
      margin-left: 100px;
      margin-top: 40px;
      width: 300px;
      height: 300px;
    }

    .hecheng {
      display: block;
      margin-left: 160px;
      width: 170px;
      height: 50px;
    }

    .tan {
      position: absolute;
      z-index: 2;
      top: 70px;
      right: 50px;
      width: 200px;
      height: 200px;
      color: #fff;

      .tp {
        width: 180px;
        margin: 10px auto;
        font-size: 20px;
        text-align: center;
      }

      .tn {
        width: 180px;
        margin: auto;
        font-size: 20px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .con {
        color: #fff;
        text-align: center;
        font-size: 20px;
        margin: 10px auto;
      }

      img {
        display: block;
        width: 150px;
        height: 150px;
        margin: auto;
      }
    }
  }

  .right {
    width: 348px;
    height: 410px;
    margin-left: 100px;
    background-image: url("../../assets/images/replacing/kuang.png");
    background-size: 100% 100%;

    .rm {
      margin-left: 10px;
      margin-top: 70px;
      width: 300px;
      height: 310px;
      overflow: scroll;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .lfi {
        flex: none;
        width: 120px;
        height: 100px;
        color: #fff;
        font-size: 10px;
        background-image: url("../../assets/images/replacing/box.png");
        background-size: 100% 100%;

        .lfip {
          margin-top: 4px;
          text-align: center;
        }

        img {
          display: block;
          margin-left: 16px;
          width: 80px;
          height: 60px;
        }

        .lfin {
          width: 80%;
          margin: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .header {
    display: none;
  }

  .bott {
    display: none;
  }

  .get {
    display: none;
  }

  .getwu {
    display: none;
  }

  .yixuan {
    display: none;
  }
}

@media (max-width: 550px) {
  .replacing {
    width: 100%;
    height: auto;
    display: block;
    margin: 10px auto;
    position: relative;
    overflow: auto;

    .left {
      display: none;
    }

    .middle {
      display: none;
    }

    .right {
      display: none;
    }

    .header {
      width: 180px;
      height: 40px;
      margin: 10px auto;
      background-image: url("../../assets/images/replacing/taihuan.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80px;
        height: 20px;
      }
    }

    .bott {
      display: block;
      width: 100%;
      height: 220px;
      overflow: hidden;

      .kexuan {
        width: 96%;
        height: 220px;
        display: block;
        margin: auto;

        .ki {
          width: 100%;
          height: 100%;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;

          .xi {
            flex: none;
            width: 22%;
            height: 110px;
            margin-left: 8px;
            margin-bottom: 10px;
            background-image: url("../../assets/images/pk/bg111.png");
            background-size: 100% 100%;

            .kp {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 80%;
              margin: auto;
              height: 20px;
              color: rgba(252, 255, 0, 1);
              font-size: 12px;

              img {
                margin-right: 4px;
                width: 12px;
                height: 12px;
              }
            }

            .xii {
              display: block;
              width: 70%;
              height: 60%;
              margin: 0px auto 0px;
            }

            .kn {
              display: block;
              margin: auto;
              color: #fff;
              width: 80%;
              overflow: hidden;
              white-space: nowrap;
              font-size: 12px;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .get {
      width: 180px;
      height: 180px;
      margin: auto;
      background-image: url("../../assets/images/replacing/reb.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .getimg {
        width: 80px;
        height: 30px;
      }
    }

    .getwu {
      position: absolute;
      z-index: 111;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 110vh;
      backdrop-filter: blur(8px);
      display: flex;
      justify-content: center;
      align-items: center;

      .getwum {
        width: 160px;
        height: 160px;

        .gti {
          width: 130px;
          height: 130px;
          margin: auto;
          background-image: url("../../assets/images/pk/bg444.png");
          background-size: 100% 100%;
          overflow: hidden;

          .ni {
            display: block;
            margin: 4px auto;
            width: 80%;
            height: 80px;
          }

          .tn {
            width: 80%;
            margin: auto;
            font-size: 14px;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tp {
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #fcff00;

            img {
              margin-right: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }

        .con {
          margin-top: 8px;
          font-size: 16px;
          text-align: center;
          color: #fff;
        }
      }
    }

    .yixuan {
      display: block;
      width: 90%;
      height: 220px;
      margin: auto;
      background-image: url("../../assets/images/replacing/wupin.png");
      background-size: 100% 100%;
      overflow: hidden;

      .yihe {
        width: 100px;
        height: 20px;
        margin: 20px auto 0px;
        text-align: center;
        line-height: 20px;
        font-size: 16px;
        color: rgba(255, 240, 0, 1);
      }

      .yimain {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 170px;
        overflow: auto;

        .yimi {
          flex: none;
          width: 80px;
          height: 110px;
          margin-top: 10px;
          margin-left: 4px;
          background-image: url("../../assets/images/pk/bg111.png");
          background-size: 100% 100%;
          position: relative;

          .yclose {
            position: absolute;
            top: -6px;
            left: 30px;
            width: 18px;
            height: 18px;
          }

          .yii {
            display: block;
            width: 70%;
            height: 60%;
            margin: 0px auto 0px;
          }

          .yn {
            display: block;
            margin: auto;
            color: #fff;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            font-size: 12px;
            text-overflow: ellipsis;
          }

          .yp {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: auto;
            height: 20px;
            color: rgba(252, 255, 0, 1);
            font-size: 12px;

            img {
              margin-right: 4px;
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
